interface Node {
  fields: {
    name: string;
  };
}
export const sortNodes = (a: Node, b: Node) => {
  if (a.fields.name < b.fields.name) {
    return -1;
  }
  if (a.fields.name > b.fields.name) {
    return 1;
  }
  return 0;
};
