import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { PRIMARY_LIGHT } from '../../constants';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 1rem;
  font-size: 0.8rem;
  font-style: italic;
`;

const CrumbInner = ({ display, to }: { display: string; to: string }) => (
  <span>
    <Link to={to}>{display}</Link>
  </span>
);

const CrumbOuter = styled.span`
  background: ${PRIMARY_LIGHT};
  padding: 0.33rem 0.67rem;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  border-top-right-radius: 4px;
`;

interface Props {
  crumbs: {
    slug: string;
    display: string;
  }[];
}
export const Subheader = (props: Props) => {
  const { crumbs } = props;

  return (
    <Container data-id="subheader">
      {crumbs.map(crumb => (
        <CrumbOuter key={crumb.slug} data-id="crumb">
          <CrumbInner display={crumb.display} to={crumb.slug} />
        </CrumbOuter>
      ))}
    </Container>
  );
};

export default Subheader;
