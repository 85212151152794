import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import { LinkRow } from '../components/Notes/LinkRow';
import { slugToTitle } from '../../utils/slug';
import { sortNodes } from '../utility/sort';
import Subheader from '../components/Subheader';
import { getIconSrc } from '../utility/icons';
import { getTheme } from '../utility/theme';

const Container2 = styled.div``;

const Container = styled.span`
  margin-right: 0.4rem;
  display: flex;

  flex-shrink: 0; /* prevent icons from narrowing */
  img {
    background: none;
    height: 1rem;
  }
`;
const getIcon = (type: string): JSX.Element => {
  const theme = getTheme();
  const src = getIconSrc(type, theme);
  return (
    <Container>
      <img src={src} />
    </Container>
  );
};
const Directory = (props: any) => {
  const base = '/';
  const CURRENT_DIR = props.uri.slice(base.length);

  const data = useStaticQuery(graphql`
    query DirQuery {
      allDirectory {
        nodes {
          absolutePath
          relativePath
          relativeDirectory
          dir
          name
          fields {
            slug
            relativeDirectorySlug
            type
            name
          }
        }
      }
      allFile {
        nodes {
          absolutePath
          dir
          relativeDirectory
          relativePath
          name
          publicURL
          fields {
            slug
            relativeDirectorySlug
            type
            name
          }
        }
      }
    }
  `);
  const raw = [...data.allDirectory.nodes, ...data.allFile.nodes];
  const filtered = raw.filter(r => r.fields && r.fields.relativeDirectorySlug === CURRENT_DIR).sort(sortNodes);
  const title = slugToTitle(props.pageContext.name);
  return (
    <Layout>
      <SEO title={title} />
      <Subheader crumbs={props.pageContext.crumbs} />
      <h1 data-id="title">{title}</h1>

      <Container2>

      {filtered.map(r => {
        const { name, type } = r.fields;
        const display = slugToTitle(name);
        const Icon = getIcon(type);
        if (type === 'pdf') {
          return (
            <LinkRow key={r.name}>
              {Icon}
              <a href={r.publicURL} target="_blank" rel="noopener noreferrer">
                {display} <i>(pdf)</i>
              </a>
            </LinkRow>
          );
        }
        return (
          <LinkRow key={r.name}>
            {Icon}
            <Link to={r.fields.slug}>{display}</Link>
          </LinkRow>
        );
      })}
      </Container2>
    </Layout>
  );
};

export default Directory;
