const _ = require('lodash');

const sluggify = (str = '') => {
  const raw = str.toLowerCase();
  // const spacesToDash = raw.replace(/( |\||\.)/gi, '-');
  const spacesToDash = raw.replace(/ |\||\./gi, '-');
  return spacesToDash;
};
/*

  base: '5. Investigations',
  ext: '. Investigations',
  name: '5',
  relativeDirectory: 'systems/01 Cardiovascular',

*/
const sluggifyNode = node => {
  const start = node.relativeDirectory;
  const end = node.internal.type === 'Directory' ? node.base : node.name;
  const raw = [start, end].join('/');
  const slug = sluggify(raw);
  if (slug[0] === '/') {
    return slug;
  }
  return `/${slug}`;
};

const slugToTitle = slug => {
  const raw = slug;
  const title = _.startCase(raw);
  return title;
};

const generateSlugCrumbs = slug => {
  const raw = slug.split('/');
  const all = raw.map((r, i) => ({
    slug: raw.slice(0, i + 1).join('/'),
    display: slugToTitle(raw[i]),
  }));
  const result = all.slice(1, all.length - 1);
  return result;
};

module.exports = {
  sluggify,
  sluggifyNode,
  slugToTitle,
  generateSlugCrumbs,
};
