import iconSrcImageDarkTheme from '../images/image-dark-theme.png';
import iconSrcImageLightTheme from '../images/image-light-theme.png';
import iconSrcPdfDarkTheme from '../images/pdf-dark-theme.png';
import iconSrcPdfLightTheme from '../images/pdf-light-theme.png';
import iconSrcMarkdownDarkTheme from '../images/markdown-dark-theme.png';
import iconSrcMarkdownLightTheme from '../images/markdown-light-theme.png';
import iconSrcFolderDarkTheme from '../images/folder-dark-theme.png';
import iconSrcFolderLightTheme from '../images/folder-light-theme.png';

export const getIconSrc = (type: string, theme = 'light') => {
  if (type === 'pdf') {
    if (theme === 'dark') {
      return iconSrcPdfDarkTheme;
    }
    return iconSrcPdfLightTheme;
  }
  if (type === 'image') {
    if (theme === 'dark') {
      return iconSrcImageDarkTheme;
    }
    return iconSrcImageLightTheme;
  }
  if (type === 'markdown') {
    if (theme === 'dark') {
      return iconSrcMarkdownDarkTheme;
    }
    return iconSrcMarkdownLightTheme;
  }
  if (type === 'dir') {
    if (theme === 'dark') {
      return iconSrcFolderDarkTheme;
    }
    return iconSrcFolderLightTheme;
  }

  // Fallbacks
  if (theme === 'dark') {
    return iconSrcMarkdownDarkTheme;
  }
  return iconSrcMarkdownLightTheme;
};
